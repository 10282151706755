<template>
	<!-- movie -->
	<div class="movieItem">
		<!-- poster -->
		<div v-if="poster" class="poster relative bg-gray-300 rounded-md" :style="poster ? { backgroundImage: 'url(' + poster + ')' } : ''">
		</div>
		<!-- poster keleton -->
		<div v-else class="poster bg-white bg-opacity-25 rounded-md animate-pulse">
		</div>
		<!-- title -->
		<p v-if="title && showTitle" class="text-white truncate font-semibold text-xs mt-2 opacity-75 lg:text-lg">{{ title }}</p>
		<!-- title skeleton -->
		<p v-else-if="showTitle" class="h-4 w-full bg-white bg-opacity-25 rounded-sm mt-3 animate-pulse"></p>
		<!-- format & language -->
		<div id="formatAndlanguage" class="flex gap-2 text-white mt-2 opacity-75" v-if="formato || lenguaje || release  || preSale">
			<p class="text-xxs uppercase font-semibold px-2 py-1 rounded-sm text-white  bg-fuchsia-600" v-if="preSale">Preventa</p>
			<p class="text-xxs uppercase font-semibold px-2 py-1 rounded-sm text-white bg-blue-600" v-if="release">Estreno</p>
			<p class="text-xxs uppercase font-semibold px-2 py-1 rounded-sm text-gray-300 bg-neutral-900 " v-if="lenguaje">{{ lenguaje }}</p>
			<p class="text-xxs uppercase font-semibold px-2 py-1 rounded-sm text-gray-300 bg-neutral-900" v-if="formato">{{ formato }}</p>
		</div>
	</div>     
</template>

<script lang="ts">
import './components.css'

export default {
	name: 'MovieItem',
	props: {
		title: String,
		poster: String,
		formato: String,
		lenguaje: String,
		release: Boolean,
		preSale: Boolean,
		showTitle: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		trimTitle: function(title: string){
			if(title.length > 30){
				return title.substring(0, 30) + '...'
			}else{
				return title
			}
		}
	}
}
</script>

<style scoped>
.movieItem{
	width: 100%;
	display: flex;
	flex-direction: column;   
	flex-shrink: 0;
	text-decoration: none;
	color: black;
}

.movieItem h3{
	height: 27px;
}

.poster{
	width: 100%;
	padding-top: 142.8%;
	position: relative;
	background-size: cover;
	background-position: center;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.2)
}

h3.loading{
	width: 100%;
	height: 25px;
}

@media (max-width: 768px){
	.movieItem h3{
			height: 40px;
	}
}
</style>
