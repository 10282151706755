import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-573d9e73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "movieItem" }
const _hoisted_2 = {
  key: 1,
  class: "poster bg-white bg-opacity-25 rounded-md animate-pulse"
}
const _hoisted_3 = {
  key: 2,
  class: "text-white truncate font-semibold text-xs mt-2 opacity-75 lg:text-lg"
}
const _hoisted_4 = {
  key: 3,
  class: "h-4 w-full bg-white bg-opacity-25 rounded-sm mt-3 animate-pulse"
}
const _hoisted_5 = {
  key: 4,
  id: "formatAndlanguage",
  class: "flex gap-2 text-white mt-2 opacity-75"
}
const _hoisted_6 = {
  key: 0,
  class: "text-xxs uppercase font-semibold px-2 py-1 rounded-sm text-white bg-fuchsia-600"
}
const _hoisted_7 = {
  key: 1,
  class: "text-xxs uppercase font-semibold px-2 py-1 rounded-sm text-white bg-blue-600"
}
const _hoisted_8 = {
  key: 2,
  class: "text-xxs uppercase font-semibold px-2 py-1 rounded-sm text-gray-300 bg-neutral-900"
}
const _hoisted_9 = {
  key: 3,
  class: "text-xxs uppercase font-semibold px-2 py-1 rounded-sm text-gray-300 bg-neutral-900"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.poster)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "poster relative bg-gray-300 rounded-md",
          style: _normalizeStyle($props.poster ? { backgroundImage: 'url(' + $props.poster + ')' } : '')
        }, null, 4))
      : (_openBlock(), _createElementBlock("div", _hoisted_2)),
    ($props.title && $props.showTitle)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.title), 1))
      : ($props.showTitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4))
        : _createCommentVNode("", true),
    ($props.formato || $props.lenguaje || $props.release  || $props.preSale)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          ($props.preSale)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, "Preventa"))
            : _createCommentVNode("", true),
          ($props.release)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, "Estreno"))
            : _createCommentVNode("", true),
          ($props.lenguaje)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString($props.lenguaje), 1))
            : _createCommentVNode("", true),
          ($props.formato)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString($props.formato), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}